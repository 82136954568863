import React from 'react';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Card from './Card';

const SortableItem  = sortableElement((props) => {
    console.log(props, 'sortableitem')
 return (<Card 
    key={`item-${props.key}`}
    cardInfo={props.cardInfo}
    numberId={props.numberId}
    handleDelete={props.handleDelete}
    index={props.index}
    
 >
 </Card>)
}
)
export default SortableItem;
