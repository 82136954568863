import React from 'react';

const Card = (props) => {
    console.log(props);
    let cardId = props.numberId;
    console.log(cardId);
    console.log(props.cardInfo);
    return (
        <div className="card">
            <div className="card-text">
            {props.cardInfo} 
            </div>
            <span className="trash-span">
                <div>
                <button className="trash-button"
                    onClick={() => {props.handleDelete(cardId)}}
                >
                    <i className="trash-icon" class="trash alternate outline icon"></i>
                </button>
                </div>
            </span>
        </div>
    )
}

export default Card;