import React from 'react';
import Card from './Card';
import SortableContainer from './SortableContainer';
import SortableItem from './SortableItem';


const CardList = (props) => {
    return (
        <div className="card-list">
        <SortableContainer 
            onSortEnd={props.onSortEnd}
            className="sortable-helper"
            pressDelay={150}
        >
            {props.cards.map((cardInfo, index) => (
            <SortableItem
            key={index}
            cardInfo={cardInfo}
            numberId={index}
            index={index}
            handleDelete={props.handleDelete}
            className="sortable-helper"
                />
        ))}
      </SortableContainer>
      </div>
    )
}

export default CardList;