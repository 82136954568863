import React from 'react';
import List from './List';

const BoardContainer = ({email}) =>  {

    return (
        <div className="board-container">
            <List 
                title="Urgent and Important"
                color="#baffc9"
                id={0}
                email={email}
            />
            <List 
                title="Not Urgent and Important"
                color="#bae1ff"
                id={1}
                email={email}
            />
            <List 
                title="Urgent and Not Important"
                color="#ffdfba"
                id={2}
                email={email}
            />
            <List 
                title="Not Urgent and Not Important"
                color="#ffb3ba"
                id={3}
                email={email}
            />
        </div>
        );
    }

export default BoardContainer;
