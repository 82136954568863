import React from 'react';
import CardList from './CardList';
import {db} from './firebase';
import arrayMove from 'array-move';


class ListContainer extends React.Component {
    
    state = {
        input: '',
        listItems0: [],
        listItems1: [],
        listItems2: [],
        listItems3: [],
        listId: null
    }

    onChange = (event) => {
        console.log(event.target.value);
        this.setState({
            input: event.target.value,
        }, this.handleSubmit)
    }

    onChangeCompleted = () => {
        this.props.onChange(this.state)
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if (this.props.id === 0) {
                        this.setState({
                            listItems0: arrayMove(this.state.listItems0, oldIndex, newIndex)
                        }, () => this.sendItemToFirebase())
            }
        if (this.props.id === 1) {
                this.setState({
                    listItems1: arrayMove(this.state.listItems1, oldIndex, newIndex)
                }, () => this.sendItemToFirebase())
            }  
        if (this.props.id === 2) {
            this.setState({
                listItems2: arrayMove(this.state.listItems2, oldIndex, newIndex)
            }, () => this.sendItemToFirebase())
            }
        if (this.props.id === 3) {
            this.setState({
                listItems3: arrayMove(this.state.listItems3, oldIndex, newIndex)
            }, () => this.sendItemToFirebase())
            }     
        }

    onKeyPress = (e) => {
        const value = e.key;
        const allowed = [
            'Enter'
        ];
        if (this.props.id === 0) {
            if (allowed.includes(value)){
                switch(value) {
                    case 'Enter' :
                        this.setState({
                            listItems0: [...this.state.listItems0, this.state.input],
                            input: ''
                        }, () => this.sendItemToFirebase()); 
                    e.preventDefault()
                        break
                    default :
                        this.setState({
                            input: '',
                            listItems0: [...this.state.listItems0, this.state.input]
                        }, () => this.sendItemToFirebase())
                        e.preventDefault()
                }
            }
        }
        if (this.props.id === 1) {
            if (allowed.includes(value)){
                switch(value) {
                    case 'Enter' :
                        this.setState({
                            listItems1: [...this.state.listItems1, this.state.input],
                            input: ''
                        }, () => this.sendItemToFirebase()); 
                    e.preventDefault()
                        break
                    default :
                        this.setState({
                            input: '',
                            listItems1: [...this.state.listItems1, this.state.input]
                        }, () => this.sendItemToFirebase())
                        e.preventDefault()
                }
            }
        }
        if (this.props.id === 2) {
            if (allowed.includes(value)){
                switch(value) {
                    case 'Enter' :
                        this.setState({
                            listItems2: [...this.state.listItems2, this.state.input],
                            input: ''
                        }, () => this.sendItemToFirebase()); 
                    e.preventDefault()
                        break
                    default :
                        this.setState({
                            input: '',
                            listItems2: [...this.state.listItems2, this.state.input]
                        }, () => this.sendItemToFirebase())
                        e.preventDefault()
                }
            }
        }
        if (this.props.id === 3) {
            if (allowed.includes(value)){
                switch(value) {
                    case 'Enter' :
                        this.setState({
                            listItems3: [...this.state.listItems3, this.state.input],
                            input: ''
                        }, () => this.sendItemToFirebase()); 
                    e.preventDefault()
                        break
                    default :
                        this.setState({
                            input: '',
                            listItems3: [...this.state.listItems3, this.state.input]
                        }, () => this.sendItemToFirebase())
                        e.preventDefault()
                }
            }
        }
     }

    onDelete = (cardId) => {
        if(this.props.id === 0) {
        let textToDelete = this.state.listItems0[cardId]
        console.log(textToDelete)
        this.setState({
            listItems0: this.state.listItems0.filter(a => a !== textToDelete)
        }, () => db.collection("user-data").doc(this.props.email).update({
            listItems0: this.state.listItems0
            })
        )  
        }
        else if(this.props.id === 1) {
            let textToDelete = this.state.listItems1[cardId]
            console.log(textToDelete)
            this.setState({
                listItems1: this.state.listItems1.filter(a => a !== textToDelete)
            }, () => db.collection("user-data").doc(this.props.email).update({
                listItems1: this.state.listItems1
                }))  
            }
            else if(this.props.id === 2) {
                let textToDelete = this.state.listItems2[cardId]
                console.log(textToDelete)
                this.setState({
                    listItems2: this.state.listItems2.filter(a => a !== textToDelete)
                }, () => db.collection("user-data").doc(this.props.email).update({
                    listItems2: this.state.listItems2
                    }))  
            }
            else {
                let textToDelete = this.state.listItems3[cardId]
                console.log(textToDelete)
                this.setState({
                    listItems3: this.state.listItems3.filter(a => a !== textToDelete)
                }, () => db.collection("user-data").doc(this.props.email).update({
                    listItems3: this.state.listItems3
                    }))  
            }
    }

    sendItemToFirebase = (state) => {
        if(this.props.id === 0) {
                db.collection("user-data").doc(this.props.email).update({
                    listItems0: this.state.listItems0
                })
                .then(function() {
                    console.log('document written')
                    })
        }
        else if(this.props.id === 1) {
            db.collection("user-data").doc(this.props.email).update({
                listItems1: this.state.listItems1
            })
            .then(function() {
                console.log('document written')
                })
         }
         else if(this.props.id === 2) {
            db.collection("user-data").doc(this.props.email).update({
                listItems2: this.state.listItems2
            })
            .then(function() {
                console.log('document written')
                })
         }
         else if(this.props.id === 3){
            db.collection("user-data").doc(this.props.email).update({
                listItems3: this.state.listItems3
            })
            .then(function() {
                console.log('document written')
                })
         }
         else {
                 console.log('error')
         }
    }

    componentDidMount() {

        db.collection("user-data").doc(this.props.email).get().then((doc) => {
            if (doc.exists) {
                    this.setState({
                        listItems0: doc.data().listItems0,
                        listItems1: doc.data().listItems1,
                        listItems2: doc.data().listItems2,
                        listItems3: doc.data().listItems3,
                    });
            }
            else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    render() {
        console.log(this.props.email);
        let listInfo = eval(`this.state.listItems${this.props.id}`)
        return (
            <div className="list-container">
                <CardList
                    cards={listInfo}
                    state={this.state}
                    handleDelete={this.onDelete}
                    onSortEnd={this.onSortEnd}
                />
                <input type="text" className="card-input" placeholder="Add another card"
                    value={this.state.input} 
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}/>
            </div>
        )
    }
}

export default ListContainer;